import React from 'react';
import '../../App.css';
import HeroSection from '../Herosection.js';
/* import Scoreboard from '../Scoreboard.js'; */

function Home() {
  return (
    <>
      <HeroSection /> 
    </>
  );
}

export default Home;